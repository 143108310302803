import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import moment from "moment";
import NoImage from "../images/no-image.png";
import classnames from "classnames";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <img
        className="w-50 m-auto"
        src={node.data?.target?.fields?.file?.url}
        alt={node.data?.target?.fields?.title}
      />
    ),
  },
};

const TODAY = moment().startOf("day");
const CURRENT_MONTH = moment().startOf("month");

const formatDate = (date) => moment(date).format("ddd, MMM Do");
const formatMonthKey = (date) => moment(date).format("YYYY-MM");
const formatDisplayMonth = (date) => moment(date).format("MMMM YYYY");

const ArtistCard = ({ fields, date }) => {
  const website = fields.websites && fields.websites[0];
  const { name, location, artistImage } = fields;
  const imageUrl = artistImage?.fields.file.url;

  return (
    <div className="card">
      <div className="card-image">
        <img className="w-100" src={imageUrl || NoImage} alt={name} />
      </div>
      <div className="card-body p-3">
        <p className="m-b-0 p-b-10">{formatDate(date)}</p>
        <h4 className="mb-2">{name}</h4>
        <p className="small-text m-b-0">
          {location && <span>from {location}</span>}
        </p>
        {website && (
          <p className="artist-site-link">
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="me-2"
            >
              website
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

const groupArtistsByMonth = (list) => {
  const grouped = list.reduce((acc, artist) => {
    if (!artist.fields.date) {
      return acc;
    }
    const futureDates = artist.fields.date.filter((date) =>
      moment(date).isAfter(CURRENT_MONTH)
    );
    futureDates.forEach((date) => {
      const monthKey = formatMonthKey(date);
      if (!acc[monthKey]) {
        acc[monthKey] = {
          displayMonth: formatDisplayMonth(date),
          artists: [],
        };
      }
      acc[monthKey].artists.push({ ...artist, date });
      acc[monthKey].artists.sort((a, b) =>
        moment(a.date, "M/D/YY").diff(moment(b.date, "M/D/YY"))
      );
    });
    return acc;
  }, {});

  return grouped;
};

const renderArtists = (list) => {
  const groupedByMonth = groupArtistsByMonth(list);

  return Object.entries(groupedByMonth)
    .sort(([monthA], [monthB]) => moment(monthA).diff(moment(monthB)))
    .map(([month, { displayMonth, artists }], idx) => {
      return (
        <div key={month}>
          <h2
            className={classnames("m-b-30", {
              "m-t-100": idx > 0,
            })}
          >
            {displayMonth}
          </h2>
          <div className="d-flex flex-column flex-md-row gap-5">
            {artists.map((artist) => (
              <ArtistCard key={artist.date} {...artist} />
            ))}
          </div>
        </div>
      );
    });
};

const LiveVineyardStage = ({ info }) => {
  return (
    <div className="container p-t-100 m-b-50">
      <h1 className="huge-text-no-quote">LIVE!</h1>
      <h2 className="">from the vineyard stage</h2>
      <p>
        Every Saturday Night, we welcome amazing performers from the region,
        Philly and beyond.
      </p>
      <div className="m-t-100 p-b-100">{renderArtists(info)}</div>
    </div>
  );
};

export default LiveVineyardStage;
